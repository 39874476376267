const theme = {
  breakpoints: ['576px', '768px', '992px', '1280px', '1600px'],
  colors: {
    dark: '#000000',
    light: '#ffffff',
    gray: {
      default: '#F7F7F7',
      dark: '#EAEAEA',
    },
    primary: '#18C654',
    warning: '#F06747',
    facebook: '#3B5998',
    twitter: '#00ACED',
  },
  opacity: {},
  overlays: {
    light: 'rgba(0,0,0,0.2)',
    hard: 'rgba(0,0,0,0.7)',
  },
  spacing: {
    small: '8px',
    normal: '16px',
    medium: '24px',
    large: '32px',
    component: '56px',
  },
  dimensions: {
    aspectRatio: {
      wide: '56.25%',
      box: '76%',
      portrait: '120%',
    },
    container: '1540px',
    hero: {
      small: '73vh',
      medium: '86vh',
      large: '100vh',
    },
  },
  space: {
    0: '0px',
    1: '8px',
    2: '16px',
    3: '24px',
    4: '32px',
    5: '40px',
    6: '48px',
    7: '56px',
    8: '64px',
    9: '72px',
    10: '80px',
    11: '88px',
    12: '96px',
    13: '104px',
    14: '112px',
    15: '120px',
    16: '128px',
    17: '136px',
    18: '144px',
    19: '152px',
    20: '160px',
  },
  fontSizes: {
    0: '11px',
    1: '12px',
    2: '14px',
    3: '16px',
    4: '18px',
    5: '24px',
  },
  letterSpacing: {
    0: '0.75px',
  },
  mixins: {
    imageObjectFit: (position = 'center') => `
      object-fit: cover;
      object-position: ${position};
      `,
    transition: (transtions = []) => {
      if (transtions.length > 0) {
        return `transition: ${transtions.map(
          transition =>
            `${transition.property}
            ${transition.time ? transition.time : `0.15s`}
            ${
              transition.easing
                ? transition.easing
                : `cubic-bezier(0.17, 0.67, 0.83, 0.67)`
            } `
        )};`
      }
    },
    positionCover: () => `
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `,
    overlay: (color = 'rgba(0,0,0,0.2)') => `
      content: "";
      background-color: ${color};
      `,
    textElipsis: () => `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      `,
  },
}

export default theme
