import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import PageList from "src/components/Header/PageList";
import Media from "src/components/Media";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

import Box from "src/components/UI/Structure/Box";
import Flex from "src/components/UI/Structure/Flex";
import Text from "src/components/UI/Text";

const MenuStyles = styled(Flex)`
  ${(props) => props.theme.mixins.transition([{ property: "transform" }])};
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: white;
  z-index: 2;
  max-width: none;
  transform: translateY(${(props) => (props.open ? "0" : "-100%")})
    translateX(0);

  ${media.md`
      max-width: 520px;
    transform: translateY(0) translateX(${(props) =>
      props.open ? "0" : "100%"});
  `}

  ${media.xl`
    max-width: 640px;
  `}

  a {
    text-decoration: none;
    color: black;
  }
`;
const Footer = styled(Flex)`
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;
const Title = styled(Box)`
  position: absolute;
  bottom: 32px;
  ${media.sm`
    position:relative;
    bottom:0;
  `}
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  handleLinkClick() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { data } = this.props;
    const socials =
      data.allWp.edges[0].node.siteOptions.acfGlobalFooter.footerSocials
        .channels;
    return (
      <MenuStyles
        px={[4, null, 10]}
        pt={[0, null, 4]}
        pb={4}
        flexDirection="column"
        justifyContent={["center", null, "space-between"]}
        open={this.state.open}
      >
        <Title order={[2, 0]} mt={["auto", 0]}>
          <Text fontSize={2} color="dark">
            <strong>HEADSTOMP</strong> PRODUCTIONS © 2019
          </Text>
        </Title>
        {this.state.open && (
          <PageList
            onClick={
              this.props.onLinkClick
                ? this.props.onLinkClick
                : () => {
                    this.handleLinkClick();
                  }
            }
          />
        )}
        <Media
          query="(min-width: 768px)"
          render={() => (
            <Footer
              pt={3}
              style={{
                textTransform: "uppercase",
              }}
              color="dark"
              fontSize={2}
              justifyContent="flex-end"
            >
              <Box mr="auto">
                <Text fontSize={2}>Följ oss på</Text>
              </Box>
              {socials.map((social, index) => (
                <Text ml={1} key={index} fontWeight="500">
                  <a rel="nofollow noreferrer" href={social.link.url}>
                    {social.link.title}
                  </a>
                </Text>
              ))}
            </Footer>
          )}
        />
      </MenuStyles>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query siteSettings {
        allWp {
          edges {
            node {
              siteOptions {
                acfGlobalFooter {
                  footerSocials {
                    title
                    text
                    channels {
                      link {
                        title
                        url
                        target
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Menu data={data} {...props} />}
  />
);

Menu.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onLinkClick: PropTypes.func,
};
