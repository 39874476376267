import React from "react";
import styled from "styled-components";
import Flex from "src/components/UI/Structure/Flex";

const SocialsStyles = styled(Flex)`
  line-height: 34px;

  a {
    color: ${(props) => props.theme.colors.light};
  }

  span {
    display: block;
  }

  .socials-title {
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const Socials = ({ title, text, channels, ...rest }) => (
  <SocialsStyles flexDirection="column">
    <span className="socials-title">{title}</span>
    <span dangerouslySetInnerHTML={{ __html: text }} />
    {channels.map((channel, i) => (
      <a
        key={i}
        href={channel.link.url}
        rel="nofollow noreferrer"
        target={channel.link.target}
      >
        {channel.link.title}
      </a>
    ))}
  </SocialsStyles>
);

export default Socials;
