import theme from './theme'

const paddingGrid = parseInt(theme.spacing.small, 10)
const paddingContainer = parseInt(theme.spacing.large, 10)

const gridTheme = {
  row: {
    padding: paddingGrid,
  },
  col: {
    padding: paddingGrid,
  },
  container: {
    padding: paddingContainer,
  },
  breakpoints: {
    // defaults below
    xl: 1600,
    lg: 1280,
    md: 992,
    sm: 768,
    xs: 576,
  },
}

export default gridTheme
