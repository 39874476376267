import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import PageTransition from "src/components/UI/PageTransition/PageTransition";
import Header from "src/components/Header/Header";
import Footer from "src/components/Footer/Footer";

import fonts from "src/styles/fonts";

const AppStyles = styled.div`
  ${fonts}
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Plain";
  justify-content: space-between;
`;

const Layout = ({ children, location }) => {
  return (
    <React.Fragment>
      <AppStyles>
        <Header />
        <PageTransition location={location}>{children}</PageTransition>
        <Footer />
      </AppStyles>
    </React.Fragment>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
