import { css } from 'styled-components';

import PlainExtraBoldWoff from './Plain-Extrabold.woff';
import PlainExtraBoldItalicWoff from './Plain-ExtraboldItalic.woff';

import PlainRegularWoff from './Plain-Regular.woff';
import PlainRegularItalicWoff from './Plain-RegularItalic.woff';


export default css`
  @font-face {
    font-family: 'Plain';
    src: url(${PlainRegularWoff});
    font-weight: 400;
    font-style: normal;
    font-display:block;
  }
  @font-face {
    font-family: 'Plain';
    src: url(${PlainRegularItalicWoff});
    font-weight: 400;
    font-style: italic;
    font-display:block;
  }
  @font-face {
    font-family: 'Plain';
    src: url(${PlainExtraBoldWoff});
    font-weight: 500;
    font-style: normal;
    font-display:block;
  }
  @font-face {
    font-family: 'Plain';
    src: url(${PlainExtraBoldItalicWoff});
    font-weight: 500;
    font-style: italic;
    font-display:block;
  }
`;
