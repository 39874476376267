import styled from "styled-components";
import Flex from "src/components/UI/Structure/Flex";

const Grid = styled(Flex)``;

Grid.defaultProps = {
  flexDirection: ["column", "row"],
  mx: "-8px",
  flexWrap: "wrap",
};

export default Grid;
