import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const OverlayStyles = styled.div`
  ${props => props.theme.mixins.transition([{ property: 'background-color' }])};
  pointer-events: ${props => (props.open ? 'click' : 'none')};
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: ${props =>
    props.open ? props.theme.overlays.hard : 'transparent'};
  cursor: pointer;
`

class Overlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open })
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open })
  }

  handleLinkClick() {
    this.setState({ menuOpen: false })
  }

  render() {
    return (
      <OverlayStyles
        open={this.state.open}
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick()
              }
        }
      />
    )
  }
}

export default Overlay

Overlay.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
}
