import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import styled from "styled-components";
import url from "url";

import Text from "src/components/UI/Text";

const PageListStyles = styled.nav`
  a {
    display: block;
    color: black;
    text-decoration: none;
  }
`;

const PageList = ({ data, onClick }) => {
  const pages = data.allWpPage.edges;
  return (
    <PageListStyles>
      {pages.map(({ node: page }, i) => (
        <Text
          key={i}
          fontSize={["38px", null, null, null, "38px", "44px"]}
          lineHeight="1.65em"
        >
          <Link
            key={page.id}
            to={page?.link ? url.parse(page.link).pathname : ""}
            onClick={onClick}
          >
            {page.title}
          </Link>
        </Text>
      ))}
    </PageListStyles>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage(sort: { fields: [menuOrder] }) {
          edges {
            node {
              id
              title
              link
            }
          }
        }
      }
    `}
    render={(data) => <PageList data={data} {...props} />}
  />
);

PageList.propTypes = {
  data: PropTypes.shape({
    allWpPage: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};
