/* eslint-disable */
import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Flex from "src/components/UI/Structure/Flex";
import Box from "src/components/UI/Structure/Box";
import Button from "src/components/UI/Button";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
const NewsletterStyles = styled.nav`
  line-height: 34px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  span {
    display: block;
  }

  .newsletter-title {
    text-transform: uppercase;
    font-weight: 500;
  }
  .text {
    display: none;
    ${media.lg`
      display:block;
    `}
  }
`;

const Input = styled("input")`
  background: none;
  color: white;
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  font-size: 14px;
  padding: 16px 0;
  width: 100%;
  ::placeholder {
    color: white;
  }
  margin-right: 12px;
  ${media.sm`
    font-size:18px;
  `}
`;

const url =
  "https://headstomp.us4.list-manage.com/subscribe/post?u=bb5adcc221e5ec0cd2e1ceffb&amp;id=39d86476c0";

class CustomForm extends Component {
  state = {
    emailValid: false,
  };

  validateEmail = (e) => {
    const email = e.target.value;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(email).toLowerCase());

    if (this.state.emailValid !== isValid) {
      this.setState({
        emailValid: isValid,
      });
    }
  };

  render() {
    const { status, onValidated } = this.props;
    const { emailValid } = this.state;

    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    return (
      <Flex mt="auto" alignItems="flex-end" fontSize={4}>
        <Input
          aria-label="Din e-postadress"
          ref={(node) => (email = node)}
          type="email"
          onChange={this.validateEmail}
          placeholder="Din e-postadress"
        />
        <Button
          label="Registrera"
          light
          disabled={!emailValid || status === "success"}
          onClick={submit}
        >
          {status === "sending" && "Skickar"}
          {status === "error" && "Något gick fel"}
          {status === "success" && "Registrerad!"}
          {!status && "Registrera"}
        </Button>
      </Flex>
    );
  }
}

const Newsletter = ({ title, text }) => (
  <NewsletterStyles>
    <Box maxWidth="400px">
      <span className="newsletter-title">{title}</span>
      <span className="text" dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  </NewsletterStyles>
);

export default Newsletter;
