import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import Link from "src/components/UI/Link";
import Box from "src/components/UI/Structure/Box";

const ButtonStyles = styled(Box)`
  cursor: pointer;
  height: ${(props) => (props.size === "large" ? "55px" : "40px")};

  ${media.lg`
    height: ${(props) =>
      (props.size === "large" && "70px") ||
      (props.size === "medium" && "48px") ||
      (props.size === "small" && "40x") ||
      "55px"};
  `}

  a,
  button {
    ${(props) =>
      props.theme.mixins.transition([
        { property: "box-shadow" },
        { property: "background" },
        { property: "color" },
      ])}

    cursor: pointer;
    box-shadow: none;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: none;
    text-transform: ${(props) =>
      props.lowercase === 1 ? "normal" : "uppercase"};
    text-decoration: none;
    text-align: center;
    line-height: 1;
    box-sizing: border-box;
    position: relative;
    border-style: solid;
    width: 100%;
    color: ${(props) =>
      props.light === 1 ? props.theme.colors.light : props.theme.colors.dark};
    padding: 0 ${(props) => props.theme.space[3]};
    border-width: ${(props) => (props.size === "small" ? "1px" : "2px")};
    border-color: ${(props) => props.theme.colors.gray.dark};
    border-radius: ${(props) => (props.size === "large" ? "50px" : "30px")};
    letter-spacing: ${(props) => props.theme.letterSpacing[0]};
    font-size: ${(props) => props.theme.fontSizes[2]};

    ${media.xl`
        padding: 0 ${(props) =>
          props.size === "small" ? props.theme.space[1] : props.theme.space[4]};
                font-size: ${(props) =>
                  props.size === "small"
                    ? props.theme.fontSizes[2]
                    : props.theme.fontSizes[4]};
    `}

    &:hover {
      color: ${(props) => props.theme.colors.dark};
      background: transparent;
      box-shadow: inset 0px 0px 0px 1px
        ${(props) => props.theme.colors.gray.dark};
    }

    &:active,
    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.4;
    }

    ${(props) =>
      props.default === 1 &&
      `
      font-size: ${(props) => props.theme.fontSizes[2]};
      font-weight:500;
    `}

    /* Filled buttons */
    ${(props) =>
      props.filled &&
      `
      border:none;
      &:hover {
        box-shadow: none;
      }
    `}

    ${(props) =>
      props.filled === "white" &&
      `
      background:${props.theme.colors.light};
      color:${props.theme.colors.dark};
      &:hover {
        background:${props.theme.colors.dark};
        color:${props.theme.colors.light};
      }
    `}

    ${(props) =>
      props.filled === "dark" &&
      `
      background:${props.theme.colors.dark};
      color:${props.theme.colors.light};
      &:hover {
        background:${props.theme.colors.light};
        color:${props.theme.colors.dark};
      }
    `}

    ${(props) =>
      props.filled === "gray" &&
      `
      background:${props.theme.colors.gray.dark};
      color:${props.theme.colors.dark};
      &:hover {
        background:${props.theme.colors.light};
        color:${props.theme.colors.dark};
      }
    `}

    ${(props) =>
      props.filled === "lightGray" &&
      `
      background:${props.theme.colors.gray.default};
      color:${props.theme.colors.dark};
      &:hover {
        background:${props.theme.colors.light};
        color:${props.theme.colors.default};
      }
    `}

    ${(props) =>
      props.filled === "facebook" &&
      `
      background:${props.theme.colors.facebook};
      color:${props.theme.colors.light};
      &:hover {
       background:${props.theme.colors.facebook};
      }
    `}

    ${(props) =>
      props.filled === "twitter" &&
      `
      background:${props.theme.colors.twitter};
      color:${props.theme.colors.light};
      &:hover {
        background:${props.theme.colors.twitter};
      }
    `}


    /* Border buttons */
    ${(props) =>
      props.border === "dark" &&
      `
      border-color:${props.theme.colors.dark};
      &:hover {
        box-shadow: inset 0px 0px 0px 1px ${props.theme.colors.dark};
        background: transparent;
      }
    `}

    ${(props) =>
      props.border === "white" &&
      `
      border-color:${props.theme.colors.light};
      color: ${props.theme.colors.light};
      &:hover {
        color: ${props.theme.colors.light};
        box-shadow: inset 0px 0px 0px 1px ${props.theme.colors.light};
        background: transparent;
      }
    `}

    ${(props) =>
      props.border === "primary" &&
      `
      border-color:${props.theme.colors.primary};
      &:hover {
        box-shadow: inset 0px 0px 0px 1px ${props.theme.colors.primary};
        background: transparent;
      }
    `}

    ${(props) =>
      props.border === "warning" &&
      `
      border-color:${props.theme.colors.warning};
      &:hover {
        box-shadow: inset 0px 0px 0px 1px ${props.theme.colors.warning};
        background: transparent;
      }
    `}
  }
`;

const Button = ({
  link,
  light,
  defaultStyle = 1,
  size,
  linkTarget,
  disabled,
  border,
  shadow,
  children,
  filled,
  type,
  width,
  lowercase,
  label,
  ...rest
}) => {
  return (
    <ButtonStyles
      light={light ? 1 : 0}
      size={size}
      shadow={shadow ? 1 : 0}
      filled={filled}
      default={defaultStyle ? 1 : 0}
      type={type}
      width={width}
      lowercase={lowercase ? 1 : 0}
      border={border ? border : 0}
      {...rest}
    >
      {link ? (
        <Link to={link} target={linkTarget}>
          {children}
        </Link>
      ) : (
        <button aria-label={label} disabled={disabled}>
          {children}
        </button>
      )}
    </ButtonStyles>
  );
};

export default Button;
