import React, { Component } from "react";
import Box from "src/components/UI/Structure/Box";

class SectionLight extends Component {
  constructor(props) {
    super(props);
    this.section = React.createRef();
    this.state = {
      sectionActive: false,
    };
  }

  componentDidMount() {
    this.checkSection();
    this.stickyTop = null;
    window.addEventListener("scroll", this.checkSection);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkSection);
  }

  checkSection = () => {
    let section = this.section.current.getBoundingClientRect();
    let scrollTop = window.pageYOffset;
    let sectionTop = section.top + scrollTop - 39;

    if (this.props.sticky && !this.stickyTop) {
      this.stickyTop = sectionTop;
    }
    if (this.props.sticky) {
      sectionTop = this.stickyTop;
    }

    let sectionBottom = sectionTop + section.height;

    if (scrollTop >= sectionTop && scrollTop <= sectionBottom) {
      document.body.classList.remove("header-dark");
      this.setState({ sectionActive: true });
    } else if (this.state.sectionActive === true) {
      document.body.classList.add("header-dark");
      this.setState({ sectionActive: false });
    }
  };

  render() {
    const { children, id, sticky = false } = this.props;

    return (
      <Box id={id} ref={this.section}>
        {children}
      </Box>
    );
  }
}

export default SectionLight;
