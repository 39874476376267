import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import styled from "styled-components";

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const Image = ({ image, className, alt }) => {
  if (!image) {
    return null;
  }
  if (typeof image !== "string") {
    return <GatsbyImage image={image} className={className} alt={alt} />;
  }
  return <StyledImage src={image} className={className} alt={alt} />;
};

export default Image;
