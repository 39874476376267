import styled from "styled-components";
import Box from "src/components/UI/Structure/Box";

const Column = styled(Box)``;

Column.defaultProps = {
  px: 1,
  width: 1,
  order: 1,
  minWidth: 0,
};

export default Column;
