import React, { Component } from 'react';
import Media from 'react-media'

class MediaComponent extends Component {

    constructor(props) {
      super(props)
      this.state = { loaded: 0 }
    }

    componentDidMount() {
      this.setState({ loaded: 1 })
    }

    render() {
      const { loaded } = this.state;
      const { render, renderBefore, query } = this.props;
      if(loaded){
        return (
          <Media
            query={query}
            render={render}
          />
        )
      } else {
        if(renderBefore){
          return renderBefore();
        }
        return null
      }
    }

}

export default MediaComponent;
