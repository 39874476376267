import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";
import { media } from "styled-bootstrap-grid";

import Text from "src/components/UI/Text";

const LogotypeStyles = styled.h1`
  width: 100%;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 0;

  ${media.lg`
      font-size: ${(props) => props.theme.fontSizes[4]};
  `}

  a {
    ${(props) =>
      props.theme.mixins.transition([{ property: "ficolorll", time: "0.3s" }])};
    color: ${(props) =>
      props.color === "light"
        ? props.theme.colors.light
        : props.theme.colors.dark};
    text-decoration: none;
  }

  span {
    font-weight: 500;
  }
`;

const Logotype = ({ copyright, color, ...rest }) => (
  <LogotypeStyles color={color} {...rest}>
    <Link to="/">
      <Text fontWeight="bold">Headstomp</Text> Productions
      {copyright && <span>Logo © {new Date().getFullYear()}</span>}
    </Link>
  </LogotypeStyles>
);

export default Logotype;

Logotype.propTypes = {
  copyright: PropTypes.bool,
};
