import styled from "styled-components";
import Box from "src/components/UI/Structure/Box";

const Container = styled(Box)``;

Container.defaultProps = {
  px: 4,
  width: 1,
  mx: "auto",
};

export default Container;
