import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import Media from "src/components/Media";

import Container from "src/components/UI/Grid/Container";
import Flex from "src/components/UI/Structure/Flex";
import Box from "src/components/UI/Structure/Box";

import MenuButton from "src/components/Header/MenuButton";
import Menu from "src/components/Header/Menu";
import Symbol from "src/components/UI/Symbol/Symbol";
import Logotype from "src/components/UI/Logotype/Logotype";
import Overlay from "src/components/Header/Overlay";

const HeaderStyles = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: ${(props) => props.theme.space[4]} 0;
  color: ${(props) => props.theme.colors.light};

  ${media.sm`
    padding: ${(props) => props.theme.space[4]} 0;
  `}
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleLinkClick() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <HeaderStyles>
        <Container>
          <Flex justifyContent="space-between">
            <Media
              query="(min-width: 991px)"
              render={() => (
                <Box>
                  <Symbol id="header-symbol" small />
                </Box>
              )}
              renderBefore={() => <Box width="24px" />}
            />
            <Box>
              <Logotype id="header-logo" />
            </Box>
            <Box>
              <MenuButton
                open={this.state.menuOpen}
                onClick={() => this.handleMenuClick()}
              />
            </Box>
          </Flex>
        </Container>
        <Menu
          open={this.state.menuOpen}
          onClick={() => this.handleMenuClick()}
          onLinkClick={() => this.handleLinkClick()}
        />
        <Overlay
          open={this.state.menuOpen}
          onClick={() => this.handleMenuClick()}
        />
      </HeaderStyles>
    );
  }
}

export default Header;

Header.propTypes = {
  siteTitle: PropTypes.string,
};
