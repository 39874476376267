import React from "react";

import Layout from "src/components/Layout/Layout";
import Wrapper from "src/components/Wrapper/Wrapper";
import "src/styles/global.scss";

export const wrapRootElement = Wrapper;

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};
