import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Link from "src/components/UI/Link";
import LogoIcon from "src/images/svg/headstomp-symbol.svg";

const SymbolStyles = styled.div`
  display: inline-block;
  margin-bottom: ${(props) => props.theme.spacing.large};
  max-width: ${(props) => (props.small ? "24px" : "67px")};

  svg {
    width: 100%;
    height: 100%;

    g {
      ${(props) =>
        props.theme.mixins.transition([{ property: "fill", time: "0.3s" }])};
      fill: ${(props) =>
        props.color === "light"
          ? props.theme.colors.light
          : props.theme.colors.dark};
    }
  }
`;

const Symbol = ({ color, ...rest }) => (
  <SymbolStyles color={color} {...rest}>
    <Link to="/">
      <LogoIcon />
    </Link>
  </SymbolStyles>
);

export default Symbol;

Symbol.propTypes = {
  siteTitle: PropTypes.bool,
};
