import React from "react";
import Text from "src/components/UI/Text";

const Label = ({ children, ...rest }) => {
  return (
    <Text
      fontSize={[2, null, null, null, 2, 4]}
      fontWeight="500"
      letterSpacing="0.75px"
      m={0}
      as="p"
      style={{
        textTransform: "uppercase",
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Label;
