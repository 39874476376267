import React from "react";
import { Location } from "@reach/router";
import { RecoilRoot } from "recoil";

import "url-search-params-polyfill";
import { ThemeProvider } from "styled-components";
import { GridThemeProvider } from "styled-bootstrap-grid";

import theme from "src/helpers/theme";
import gridTheme from "src/helpers/gridTheme";

const Wrapper = ({ element }) => {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <Location>
            {({ location }) => {
              return element;
            }}
          </Location>
        </GridThemeProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default Wrapper;
