import React from "react";
import styled from "styled-components";

import Image from "src/components/UI/Image";
import Label from "src/components/UI/Typography/Label";
import Text from "src/components/UI/Text";
import Flex from "src/components/UI/Structure/Flex";
import Box from "src/components/UI/Structure/Box";
import Link from "src/components/UI/Link";

const ImageStyles = styled(Box)`
  width: 100%;
  padding-top: 133%;
  position: relative;

  & > div {
    position: absolute !important;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled(Flex)`
  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Contact = ({ label, fontSize, contact, alignBottom }) => {
  const { name, role, mail, phoneNumbers, image } = contact;
  return (
    <Wrapper height={alignBottom ? "100%" : null} flexDirection="column">
      {image && (
        <ImageStyles mb={[3, null, 3]}>
          <Image image={image ? image : null} className="image" />
        </ImageStyles>
      )}
      {label && <Label fontSize={fontSize}>{label}</Label>}
      {role && <Text fontSize={fontSize}>{role}</Text>}
      {name && <Text fontSize={fontSize}>{name}</Text>}
      {phoneNumbers &&
        phoneNumbers.map((phone, i) => (
          <Text key={i} fontSize={fontSize}>
            {phone.number}
          </Text>
        ))}
      {mail && (
        <Text mt={alignBottom ? "auto" : null} fontSize={fontSize}>
          <Link to={`mailto:${mail}`}>{mail}</Link>
        </Text>
      )}
    </Wrapper>
  );
};

Contact.defaultProps = {
  fontSize: 4,
  alignBottom: true,
};

export default Contact;
