import React from 'react'
import styled from 'styled-components'

const DetailStyles = styled.nav`
  line-height: 34px;

  span {
    display: block;
  }

  .details-title {
    text-transform: uppercase;
    font-weight: 500;
  }
`

const Details = ({ title, text }) => (
  <DetailStyles>
    <span className="details-title">{title}</span>
    <span dangerouslySetInnerHTML={{ __html: text }} />
  </DetailStyles>
)

export default Details
