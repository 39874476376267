import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import Box from "src/components/UI/Structure/Box";

const MenuButtonStyles = styled.button`
  ${(props) => props.theme.mixins.transition([{ property: "transform" }])};
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 32px;
  right: 32px;
  width: auto;
  padding: 16px 8px;
  position: fixed;
  z-index: 10;
  height: auto;
  cursor: pointer;
  transform: translateY(-10px) translateX(8px);
  & > div {
    ${(props) =>
      props.theme.mixins.transition([
        { property: "transform" },
        { property: "background-color" },
      ])};
    width: 30px;
    height: 2px;
    &:last-child  {
      top: -2px;
      position: relative;
      transform: translateY(8px);
    }
  }
  &.active {
    transform: translateY(-6px) translateX(8px);
    ${media.sm`
      transform: translateY(0px) translateX(8px);
    `}
    & > div {
      background: ${(props) => props.theme.colors.dark} !important;
    }
    div:first-child {
      transform: rotate(45deg);
    }
    div:last-child {
      transform: rotate(-45deg);
    }
  }
`;

class MenuButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <MenuButtonStyles
        aria-label="Meny"
        id="menu-button"
        className={this.state.open ? "active" : ""}
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick();
              }
        }
      >
        <Box />
        <Box />
      </MenuButtonStyles>
    );
  }
}

export default MenuButton;

MenuButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
};
