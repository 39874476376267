import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Media from "src/components/Media";
import styled from "styled-components";

import SectionLight from "src/components/Modules/SectionLight";
import Logotype from "src/components/UI/Logotype/Logotype";
import Symbol from "src/components/UI/Symbol/Symbol";
import Contact from "src/components/Modules/Contact";
import Details from "src/components/Footer/Details";
import Socials from "src/components/Footer/Socials";
import Newsletter from "src/components/Footer/Newsletter";

import Container from "src/components/UI/Grid/Container";
import Grid from "src/components/UI/Grid/Grid";
import Column from "src/components/UI/Grid/Column";

const StyledFooter = styled.div`
  a {
    color: ${(props) => props.theme.colors.light};
  }
`;

const Footer = ({ data, ...rest }) => {
  const contacts =
    data.allWp.edges[0].node.siteOptions.acfGlobalFooter.footerContacts;
  const details =
    data.allWp.edges[0].node.siteOptions.acfGlobalFooter.footerDetails;
  const newsletter =
    data.allWp.edges[0].node.siteOptions.acfGlobalFooter.footerNewsletter;
  const socials =
    data.allWp.edges[0].node.siteOptions.acfGlobalFooter.footerSocials;

  return (
    <div>
      <SectionLight>
        <StyledFooter>
          <Container
            pt={[4, null, null, null, 14]}
            pb={[0, null, null, null, 10]}
            bg="dark"
            color="light"
          >
            <Grid justifyContent="space-between">
              {/* Branding */}
              <Column
                width={["auto", null, null, null, 3 / 12]}
                textAlign={["left", null, null, null, null, "center"]}
                mb={[14]}
              >
                <Grid
                  flexDirection="column"
                  alignItems={["flex-start", null, "center"]}
                >
                  <Media
                    query="(min-width: 1280px)"
                    render={() => (
                      <Column width="1">
                        <Symbol color="light" />
                      </Column>
                    )}
                  />
                  <Column width="1">
                    <Logotype color="light" />
                  </Column>
                </Grid>
              </Column>
              <Column width={[1, null, null, null, 8 / 12]}>
                {/* Contacts */}
                {contacts && (
                  <Grid pb={[0, null, null, null, 3]} flexDirection="row">
                    {contacts.map(({ contact }) => {
                      return contact?.databaseId ? (
                        <Column
                          key={contact.databaseId}
                          mb={4}
                          width={[6 / 12, null, null, 3 / 12, 3 / 12, 3 / 12]}
                        >
                          <Contact
                            fontSize={[0, 1, 2, null, null, 4]}
                            label={contact.title}
                            contact={{
                              name: "", // Keep blank
                              role: contact.acfStaffPage.role,
                              mail: contact.acfStaffPage.mail,
                              phoneNumbers: contact.acfStaffPage.phoneNumbers,
                            }}
                          />
                        </Column>
                      ) : null;
                    })}
                  </Grid>
                )}
                {/* Border */}
                <Media
                  query="(min-width: 1280px)"
                  render={() => (
                    <Grid>
                      <Column height={"1px"} bg={"rgba(255,255,255,0.2)"} />
                    </Grid>
                  )}
                />
                {/* Information */}
                <Grid
                  pt={[0, null, null, null, 7]}
                  fontSize={[2, null, null, null, 2, 4]}
                  flexDirection="row"
                >
                  <Column width={[6 / 12, null, null, 3 / 12]} mb={4}>
                    {details && (
                      <Details title={details.title} text={details.text} />
                    )}
                  </Column>
                  <Column width={[6 / 12, null, null, 3 / 12]} mb={4}>
                    {socials && (
                      <Socials
                        title={socials.title}
                        text={socials.text}
                        channels={socials.channels}
                        {...rest}
                      />
                    )}
                  </Column>
                  <Column
                    width={[1, null, null, 6 / 12]}
                    mb={4}
                    mt={[14, null, null, 0]}
                  >
                    {newsletter && (
                      <Newsletter
                        title={newsletter.title}
                        text={newsletter.text}
                      />
                    )}
                  </Column>
                </Grid>
              </Column>
            </Grid>
          </Container>
        </StyledFooter>
      </SectionLight>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query footerSettings {
        allWp {
          edges {
            node {
              siteOptions {
                acfGlobalFooter {
                  footerDetails {
                    title
                    text
                  }
                  footerContacts {
                    contact {
                      ... on WpStaff {
                        databaseId
                        title
                        acfStaffPage {
                          role
                          mail
                          phoneNumbers {
                            number
                          }
                        }
                      }
                    }
                  }
                  footerNewsletter {
                    title
                    text
                  }
                  footerSocials {
                    title
                    text
                    channels {
                      link {
                        title
                        url
                        target
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);

Footer.propTypes = {
  data: PropTypes.shape({
    allWp: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            options: PropTypes.shape({
              footerDetails: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
              }).isRequired,
              footerContacts: PropTypes.arrayOf(
                PropTypes.shape({
                  contact: PropTypes.shape({
                    databaseId: PropTypes.number,
                    title: PropTypes.string,
                    acf: PropTypes.shape({
                      staff_title: PropTypes.string,
                      staff_mail: PropTypes.string,
                      staff_phoneNumbers: PropTypes.arrayOf(
                        PropTypes.shape({
                          number: PropTypes.string,
                        })
                      ),
                    }),
                  }).isRequired,
                }).isRequired
              ).isRequired,
              footerNewsletter: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
              }).isRequired,
              footerSocials: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
                channels: PropTypes.arrayOf(
                  PropTypes.shape({
                    link: PropTypes.shape({
                      title: PropTypes.string,
                      url: PropTypes.string,
                      target: PropTypes.string,
                    }).isRequired,
                  })
                ),
              }).isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};
