import styled from "styled-components";

import {
  space,
  fontSize,
  color,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
  fontFamily,
  fontStyle,
  width,
} from "styled-system";

const Text = styled.span`
  ${space};
  ${width};
  ${fontSize};
  ${color};
  ${textAlign};
  ${lineHeight};
  ${fontWeight};
  ${letterSpacing};
  ${fontFamily};
  ${fontStyle};
`;

Text.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...fontFamily.propTypes,
  ...fontStyle.propTypes,
  ...width.propTypes,
};

Text.defaultProps = {
  as: "span",
};

export default Text;
